/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

//bootstrap
import 'bootstrap/dist/css/bootstrap.min.css'
// custom fonts
import './src/static/fonts/fonts.css'
